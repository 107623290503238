import { default as aboutggr3etxHM3Meta } from "/home/webpages/hochwacht-frontend-test/pages/about.vue?macro=true";
import { default as _91id_93M97zv0p7ZQMeta } from "/home/webpages/hochwacht-frontend-test/pages/agegroups/[id].vue?macro=true";
import { default as contacteGMMDejP9YMeta } from "/home/webpages/hochwacht-frontend-test/pages/contact.vue?macro=true";
import { default as documentsEBUURs18F4Meta } from "/home/webpages/hochwacht-frontend-test/pages/documents.vue?macro=true";
import { default as donationZi6D19oSyjMeta } from "/home/webpages/hochwacht-frontend-test/pages/donation.vue?macro=true";
import { default as equipmentmGof1P13RUMeta } from "/home/webpages/hochwacht-frontend-test/pages/equipment.vue?macro=true";
import { default as faqp9BuOtBJFeMeta } from "/home/webpages/hochwacht-frontend-test/pages/faq.vue?macro=true";
import { default as _91id_93b4vDsrPeMPMeta } from "/home/webpages/hochwacht-frontend-test/pages/galleries/[id].vue?macro=true";
import { default as indexb4NOwAvjcjMeta } from "/home/webpages/hochwacht-frontend-test/pages/galleries/index.vue?macro=true";
import { default as impressumlpLakklJh5Meta } from "/home/webpages/hochwacht-frontend-test/pages/impressum.vue?macro=true";
import { default as indexte47ghYlbVMeta } from "/home/webpages/hochwacht-frontend-test/pages/index.vue?macro=true";
import { default as privacypolicysgwu6KNtVuMeta } from "/home/webpages/hochwacht-frontend-test/pages/privacypolicy.vue?macro=true";
import { default as rental9a4O4suHajMeta } from "/home/webpages/hochwacht-frontend-test/pages/rental.vue?macro=true";
import { default as rentalimagesrtt9kzCDHnMeta } from "/home/webpages/hochwacht-frontend-test/pages/rentalimages.vue?macro=true";
import { default as _91_46_46_46slug_93Mwnt3oRxDwMeta } from "/home/webpages/hochwacht-frontend-test/pages/resources/[...slug].vue?macro=true";
export default [
  {
    name: aboutggr3etxHM3Meta?.name ?? "about",
    path: aboutggr3etxHM3Meta?.path ?? "/about",
    meta: aboutggr3etxHM3Meta || {},
    alias: aboutggr3etxHM3Meta?.alias || [],
    redirect: aboutggr3etxHM3Meta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91id_93M97zv0p7ZQMeta?.name ?? "agegroups-id",
    path: _91id_93M97zv0p7ZQMeta?.path ?? "/agegroups/:id()",
    meta: _91id_93M97zv0p7ZQMeta || {},
    alias: _91id_93M97zv0p7ZQMeta?.alias || [],
    redirect: _91id_93M97zv0p7ZQMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/agegroups/[id].vue").then(m => m.default || m)
  },
  {
    name: contacteGMMDejP9YMeta?.name ?? "contact",
    path: contacteGMMDejP9YMeta?.path ?? "/contact",
    meta: contacteGMMDejP9YMeta || {},
    alias: contacteGMMDejP9YMeta?.alias || [],
    redirect: contacteGMMDejP9YMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: documentsEBUURs18F4Meta?.name ?? "documents",
    path: documentsEBUURs18F4Meta?.path ?? "/documents",
    meta: documentsEBUURs18F4Meta || {},
    alias: documentsEBUURs18F4Meta?.alias || [],
    redirect: documentsEBUURs18F4Meta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: donationZi6D19oSyjMeta?.name ?? "donation",
    path: donationZi6D19oSyjMeta?.path ?? "/donation",
    meta: donationZi6D19oSyjMeta || {},
    alias: donationZi6D19oSyjMeta?.alias || [],
    redirect: donationZi6D19oSyjMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/donation.vue").then(m => m.default || m)
  },
  {
    name: equipmentmGof1P13RUMeta?.name ?? "equipment",
    path: equipmentmGof1P13RUMeta?.path ?? "/equipment",
    meta: equipmentmGof1P13RUMeta || {},
    alias: equipmentmGof1P13RUMeta?.alias || [],
    redirect: equipmentmGof1P13RUMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/equipment.vue").then(m => m.default || m)
  },
  {
    name: faqp9BuOtBJFeMeta?.name ?? "faq",
    path: faqp9BuOtBJFeMeta?.path ?? "/faq",
    meta: faqp9BuOtBJFeMeta || {},
    alias: faqp9BuOtBJFeMeta?.alias || [],
    redirect: faqp9BuOtBJFeMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _91id_93b4vDsrPeMPMeta?.name ?? "galleries-id",
    path: _91id_93b4vDsrPeMPMeta?.path ?? "/galleries/:id()",
    meta: _91id_93b4vDsrPeMPMeta || {},
    alias: _91id_93b4vDsrPeMPMeta?.alias || [],
    redirect: _91id_93b4vDsrPeMPMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/galleries/[id].vue").then(m => m.default || m)
  },
  {
    name: indexb4NOwAvjcjMeta?.name ?? "galleries",
    path: indexb4NOwAvjcjMeta?.path ?? "/galleries",
    meta: indexb4NOwAvjcjMeta || {},
    alias: indexb4NOwAvjcjMeta?.alias || [],
    redirect: indexb4NOwAvjcjMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/galleries/index.vue").then(m => m.default || m)
  },
  {
    name: impressumlpLakklJh5Meta?.name ?? "impressum",
    path: impressumlpLakklJh5Meta?.path ?? "/impressum",
    meta: impressumlpLakklJh5Meta || {},
    alias: impressumlpLakklJh5Meta?.alias || [],
    redirect: impressumlpLakklJh5Meta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexte47ghYlbVMeta?.name ?? "index",
    path: indexte47ghYlbVMeta?.path ?? "/",
    meta: indexte47ghYlbVMeta || {},
    alias: indexte47ghYlbVMeta?.alias || [],
    redirect: indexte47ghYlbVMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacypolicysgwu6KNtVuMeta?.name ?? "privacypolicy",
    path: privacypolicysgwu6KNtVuMeta?.path ?? "/privacypolicy",
    meta: privacypolicysgwu6KNtVuMeta || {},
    alias: privacypolicysgwu6KNtVuMeta?.alias || [],
    redirect: privacypolicysgwu6KNtVuMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: rental9a4O4suHajMeta?.name ?? "rental",
    path: rental9a4O4suHajMeta?.path ?? "/rental",
    meta: rental9a4O4suHajMeta || {},
    alias: rental9a4O4suHajMeta?.alias || [],
    redirect: rental9a4O4suHajMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/rental.vue").then(m => m.default || m)
  },
  {
    name: rentalimagesrtt9kzCDHnMeta?.name ?? "rentalimages",
    path: rentalimagesrtt9kzCDHnMeta?.path ?? "/rentalimages",
    meta: rentalimagesrtt9kzCDHnMeta || {},
    alias: rentalimagesrtt9kzCDHnMeta?.alias || [],
    redirect: rentalimagesrtt9kzCDHnMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/rentalimages.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Mwnt3oRxDwMeta?.name ?? "resources-slug",
    path: _91_46_46_46slug_93Mwnt3oRxDwMeta?.path ?? "/resources/:slug(.*)*",
    meta: _91_46_46_46slug_93Mwnt3oRxDwMeta || {},
    alias: _91_46_46_46slug_93Mwnt3oRxDwMeta?.alias || [],
    redirect: _91_46_46_46slug_93Mwnt3oRxDwMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/resources/[...slug].vue").then(m => m.default || m)
  }
]